const messages = {
	en: {
		lang: 'English',
		mainTitle: 'Tezdet - Taxi, Travel, Delivery, Eats, Maps.',
		about: 'About',
		pageNotFound: 'Page Not Found',
		adminPanel: 'Admin-Panel',
		admins: 'Admins',
		users: 'Users',
		orders: 'Orders',
		login: 'Login',
		logout: 'Log out',
		home: 'Home',
		username: 'Username',
		password: 'Password',
		invalidUsernameOrPassword: 'Invalid username or password.',
		help: 'Help',
		support: 'Support',
		cities: 'Cities',
		city: 'City',
		hello: 'Hello :name!',
		count: 'Count',
		passenger: 'Passenger',
		driver: 'Driver',
		registered: 'Registered',
		unregistered: 'Unregistered',
		enterRequest: 'Enter request',
		search: 'Search',
		noUsers: 'No users',
		user: 'User',
		notFound: 'Not found',
		noCity: 'No city',
		language: 'Language',
		userAgent: 'User Agent',
		phone: 'Phone',
		balance: 'Balance',
		userMode: 'User mode',
		car: 'Car',
		noCar: 'No car registered',
		refillBalance: 'Refill balance',
		addCar: 'Add car',
		close: 'Close',
		error: 'Error',
		unknownError: 'Unknown error.',
		success: 'Success',
		newBalance: 'New balance: :balance :currency.',
		brand: 'Brand',
		model: 'Model',
		color: 'Color',
		number: 'Number',
		email: 'Email',
		write: 'Write',
		allCities: 'All cities',
		onlyDrivers: 'Only drivers',
		noOrders: 'No orders',
		onlyAccepted: 'Only accepted',
		from: 'From',
		to: 'To',
		comment: 'Comment',
		data: 'Data',
		price: 'Price',
		driverPrice: 'Driver price',
		state: 'State',
		calling: 'Calling',
		coming: 'Coming',
		came: 'Came',
		offers: 'Offers',
		currency: 'Currency',
		course: 'Course',
		min: 'Min',
		max: 'Max',
		step: 'Step',
		tengeMin: 'Min tenge',
		tengeOver: 'Tenge over',
		tengeStep: 'Tenge step',
		free: 'Free',
		isTheFree: 'Free',
		tariffMode: 'Tariff mode',
		percent: 'Percent',
		hourly: 'Hourly',
		percentAndHourly: 'Percent and hourly',
		hours: 'Hours',
		yes: 'Yes',
		no: 'No',
		h: 'h.',
		save: 'Save',
		enableNotification: 'Enable notification',
		disableNotification: 'Disable notification',
		accepted: 'Accepted',
		notAccepted: 'Not accepted',
		completedOfAccepted: 'Completed of accepted',
		notCompletedOfAccepted: 'Not completed of accepted',

		daterangepicker: {
			applyLabel: 'Apply',
			cancelLabel: 'Cancel',
			fromLabel: 'From',
			toLabel: 'To',
			weekLabel: 'W',
			daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			monthNames: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
		},

		tenge: 'Tenge',
		ruble: 'Ruble',
		dollar: 'Dollar',
		isActive: 'Is active?',
		active: 'Active',
		notActive: 'Not active',
		unknown: 'Unknown',
		contacts: 'Contacts',
		privacy: 'Privacy',
		userAgreement: 'User Agreement',
		downloadTheApp: 'Download the App',
		socialNetworks: 'Social Networks',
		avatar: 'Avatar',
		lastLogin: 'Last login',
		createdAt: 'Created at',
		gender: 'Gender',
		birthday: 'Birthday',
		notSet: 'Not set',
		male: 'Male',
		female: 'Female',
		rating: 'Rating',
		rideCount: 'Ride count',
		driveCount: 'Drive count',
		completed: 'Completed',
		canceledByPassenger: 'Canceled by passenger',
		canceledByDriver: 'Canceled by driver',
		datetime: 'Datetime',
		rideHistory: 'Ride History',
		carpool: 'Carpool',
		whichTariff: 'Which tariff',
		toRefillYourBalanceWriteToUsOnWhatsApp:
			'To refill your balance write to us on WhatsApp.',
		isNumberOfPersonInputEnabled: 'Is number of person input enabled',
		isCarpoolEnabled: 'Is carpool enabled',
		showOldOrders: 'Show old orders',
		takeOrderImmediately: 'Take order immediately',
		spendBalanceOn: 'Spend balance on',
		onComplete: 'On complete',
		onAccept: 'On accept',
		noAccess: 'No access',
		empty: 'Empty',
		telegramGroupChatId: 'Telegram group chat ID',
		telegramBotToken: 'Telegram bot token',
		telegramGroup: 'Telegram group',
		timezone: 'Timezone',
		driversOnTheMapTimeout: 'Time of drivers on the map (seconds)',
		inFreeWaitFor: 'How many seconds to wait in the free plan',
		isFreeTariffEnabled: 'Is free tariff enabled',
		clone: 'Clone',
		name: 'Name',
		location: 'Location',
	},

	ru: {
		lang: 'Русский',
		mainTitle: 'Tezdet - Такси, Путешествие, Доставка, Еда, Карта.',
		about: 'О нас',
		pageNotFound: 'Страница не найдена',
		adminPanel: 'Админ-панель',
		admins: 'Админы',
		users: 'Пользователи',
		orders: 'Заказы',
		login: 'Войти',
		logout: 'Выйти',
		home: 'Главная страница',
		username: 'Имя пользователя',
		password: 'Пароль',
		invalidUsernameOrPassword: 'Неправильное имя пользователя или пароль.',
		help: 'Помощь',
		support: 'Служба поддержки',
		cities: 'Города',
		city: 'Город',
		hello: 'Привет :name!',
		count: 'Количество',
		passenger: 'Пассажир',
		driver: 'Водитель',
		registered: 'Зарегистрирован',
		unregistered: 'Незарегистрирован',
		enterRequest: 'Введите запрос',
		search: 'Поиск',
		noUsers: 'Нет пользователей',
		user: 'Пользователь',
		notFound: 'Не найден(а)',
		noCity: 'Город не установлен',
		language: 'Язык',
		userAgent: 'Агент пользователя',
		phone: 'Телефон номер',
		balance: 'Баланс',
		userMode: 'Режим пользователя',
		car: 'Автомобиль',
		noCar: 'Машина не зарегистрирована',
		refillBalance: 'Пополнить баланс',
		addCar: 'Добавить машину',
		close: 'Закрыть',
		error: 'Ошибка',
		unknownError: 'Неизвестная ошибка.',
		success: 'Успех',
		newBalance: 'Новый баланс: :balance :currency.',
		brand: 'Бренд',
		model: 'Модель',
		color: 'Цвет',
		number: 'Номер',
		email: 'Email',
		write: 'Написать',
		allCities: 'Все города',
		onlyDrivers: 'Только водители',
		noOrders: 'Нет заказов',
		onlyAccepted: 'Только принятые',
		from: 'От',
		to: 'До',
		comment: 'Комментарий',
		data: 'Данные',
		price: 'Цена',
		driverPrice: 'Цена водителя',
		state: 'Статус',
		calling: 'Вызов',
		coming: 'Едет',
		came: 'Приехал(а)',
		offers: 'Предложения',
		currency: 'Валюта',
		course: 'Курс',
		min: 'Мин.',
		max: 'Макс.',
		step: 'Шаг',
		tengeMin: 'Мин. тенге',
		tengeOver: 'Тенге от',
		tengeStep: 'Шаг тенге',
		free: 'Бесплатно',
		isTheFree: 'Бесплатный',
		tariffMode: 'Режим тарифа',
		percent: 'Процент',
		hourly: 'Часовой',
		percentAndHourly: 'Процент и часовой',
		hours: 'Часы',
		yes: 'Да',
		no: 'Нет',
		h: 'ч.',
		save: 'Сохранить',
		enableNotification: 'Включить уведомление',
		disableNotification: 'Выключить уведомление',
		accepted: 'Принятые',
		notAccepted: 'Не принятые',
		completedOfAccepted: 'Завершенные от принятых',
		notCompletedOfAccepted: 'Не завершенные от принятых',

		daterangepicker: {
			applyLabel: 'Применить',
			cancelLabel: 'Отмена',
			fromLabel: 'От',
			toLabel: 'До',
			weekLabel: 'Н',
			daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
			monthNames: [
				'Январь',
				'Февраль',
				'Март',
				'Апрель',
				'Май',
				'Июнь',
				'Июль',
				'Август',
				'Сентябрь',
				'Октябрь',
				'Ноябрь',
				'Декабрь',
			],
		},

		tenge: 'Тенге',
		ruble: 'Рубль',
		dollar: 'Доллар',
		isActive: 'Активный?',
		active: 'Активный',
		notActive: 'Не активный',
		unknown: 'Неизвестно',
		contacts: 'Контакты',
		privacy: 'Конфиденциальность',
		userAgreement: 'Пользовательское соглашение',
		downloadTheApp: 'Установить приложение',
		socialNetworks: 'Социальные сети',
		avatar: 'Изображение',
		lastLogin: 'Был(а) в сети',
		createdAt: 'Создан',
		gender: 'Пол',
		birthday: 'День рождения',
		notSet: 'Не установлен(а)',
		male: 'Мужской',
		female: 'Женский',
		rating: 'Рейтинг',
		rideCount: 'Количество поездок',
		driveCount: 'Количество перевозок',
		completed: 'Выполнен',
		canceledByPassenger: 'Отменен пассажиром',
		canceledByDriver: 'Отменен водителем',
		datetime: 'Дата и время',
		rideHistory: 'История поездок',
		carpool: 'Попутчик',
		whichTariff: 'Какой тариф',
		toRefillYourBalanceWriteToUsOnWhatsApp:
			'Чтобы пополнить ваш баланс, напишите нам в WhatsApp.',
		isNumberOfPersonInputEnabled: 'Включен ли ввод количества человек',
		isCarpoolEnabled: 'Включен ли совместное использование автомобиля',
		showOldOrders: 'Показать старые заказы',
		takeOrderImmediately: 'Принять заказ немедленно',
		spendBalanceOn: 'Потратить баланс',
		onComplete: 'По завершении',
		onAccept: 'При принятии',
		noAccess: 'Нет доступа',
		empty: 'Пусто',
		telegramGroupChatId: 'ID Телеграм группы',
		telegramBotToken: 'Токен Телеграм бота',
		telegramGroup: 'Телеграм группа',
		timezone: 'Часовой пояс',
		driversOnTheMapTimeout: 'Время водителей на карте (секунд)',
		inFreeWaitFor: 'Сколько секунд ждать в беспланом тарифе',
		isFreeTariffEnabled: 'Включен ли бесплатный тариф',
		clone: 'Клонировать',
		name: 'Название',
		location: 'Местоположение',
	},

	kk: {
		lang: 'Қазақша',
		mainTitle: 'Tezdet - Такси, Саяхат, Жеткізу, Тағам, Карта.',
		about: 'Біз туралы',
		pageNotFound: 'Бет табылған жоқ',
		adminPanel: 'Админ панелі',
		admins: 'Админдер',
		users: 'Қолданушылар',
		orders: 'Тапсырыстар',
		login: 'Кіру',
		logout: 'Шығу',
		home: 'Басты бет',
		username: 'Қолданушы аты',
		password: 'Құпия сөз',
		invalidUsernameOrPassword: 'Қолданушы аты немесе құпия сөз қате терілді.',
		help: 'Көмек',
		support: 'Қолдау қызметі',
		cities: 'Қалалар',
		city: 'Қала',
		hello: 'Сәлем :name!',
		count: 'Саны',
		passenger: 'Жолаушы',
		driver: 'Жүргізуші',
		registered: 'Тіркелген',
		unregistered: 'Тіркелмеген',
		enterRequest: 'Сұранысты енгізіңіз',
		search: 'Іздеу',
		noUsers: 'Қолданушылар жоқ',
		user: 'Қолданушы',
		notFound: 'Табылған жоқ',
		noCity: 'Қала бекітілмеген',
		language: 'Тілі',
		userAgent: 'Пайдаланушы агенті',
		phone: 'Телефон нөмірі',
		balance: 'Балансы',
		userMode: 'Пайдаланушы режимі',
		car: 'Көлігі',
		noCar: 'Көлік тіркелмеген',
		refillBalance: 'Балансты толтыру',
		addCar: 'Көлікті тіркеу',
		close: 'Жабу',
		error: 'Қате',
		unknownError: 'Белгісіз қате.',
		success: 'Сәтті',
		newBalance: 'Жаңа баланс: :balance :currency.',
		brand: 'Бренді',
		model: 'Моделі',
		color: 'Түсі',
		number: 'Нөмірі',
		email: 'Email',
		write: 'Жазу',
		allCities: 'Барлық қала',
		onlyDrivers: 'Тек жүргізушілер',
		noOrders: 'Тапсырыстар жоқ',
		onlyAccepted: 'Тек қабылданған',
		from: 'Қай жерден',
		to: 'Қай жерге',
		comment: 'Коммент',
		data: 'Деректері',
		price: 'Бағасы',
		driverPrice: 'Жүргізуші бағасы',
		state: 'Статусы',
		calling: 'Шақыруда',
		coming: 'Жолда',
		came: 'Келді',
		offers: 'Ұсыныстар',
		currency: 'Валюта',
		course: 'Курс',
		min: 'Мин.',
		max: 'Макс.',
		step: 'Қадам',
		tengeMin: 'Мин. теңге',
		tengeOver: 'Теңге бастамасы',
		tengeStep: 'Теңге қадамы',
		free: 'Тегін',
		isTheFree: 'Тегін',
		tariffMode: 'Тарифтік режим',
		percent: 'Пайыз',
		hourly: 'Сағатқа',
		percentAndHourly: 'Пайыз және сағатқа',
		hours: 'Сағаттар',
		yes: 'Иә',
		no: 'Жоқ',
		h: 'с.',
		save: 'Сақтау',
		enableNotification: 'Хабарландыруды қосу',
		disableNotification: 'Хабарландаруды өшіру',
		accepted: 'Қабылданғандар',
		notAccepted: 'Қабылданмабағандар',
		completedOfAccepted: 'Қабылданғандардан орындылғандары',
		notCompletedOfAccepted: 'Қабылданғандардан орындалмағандары',

		daterangepicker: {
			applyLabel: 'Қолдану',
			cancelLabel: 'Кері',
			fromLabel: 'Бастап',
			toLabel: 'Дейін',
			weekLabel: 'А',
			daysOfWeek: ['Жс', 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сб'],
			monthNames: [
				'Қаңтар',
				'Ақпан',
				'Наурыз',
				'Сәуір',
				'Мамыр',
				'Маусым',
				'Шілде',
				'Тамыз',
				'Қыркүйек',
				'Қазан',
				'Қараша',
				'Желтоқсан',
			],
		},

		tenge: 'Теңге',
		ruble: 'Рубль',
		dollar: 'Доллар',
		isActive: 'Белсендіме?',
		active: 'Белсенді',
		notActive: 'Белсенді емес',
		unknown: 'Белгісіз',
		contacts: 'Байланаыс',
		privacy: 'Құпиялық саясат',
		userAgreement: 'Пайдаланушы келсімі',
		downloadTheApp: 'Қосымшаны жүктеу',
		socialNetworks: 'Әлеуметтік желілер',
		avatar: 'Суреті',
		lastLogin: 'Соңғы кірген уақыты',
		createdAt: 'Тіркелген күні',
		gender: 'Жынысы',
		birthday: 'Туған күні',
		notSet: 'Орнатылмаған',
		male: 'Ер',
		female: 'Әйел',
		rating: 'Рейтингі',
		rideCount: 'Таксиге мінген саны',
		driveCount: 'Қанша адам апарған саны',
		completed: 'Орындалды',
		canceledByPassenger: 'Жолаушы бас тартты',
		canceledByDriver: 'Жүргізуші бас тартты',
		datetime: 'Күні және уақыты',
		rideHistory: 'Саяхат тарихы',
		carpool: 'Жолсерік',
		whichTariff: 'Қандай тариф',
		toRefillYourBalanceWriteToUsOnWhatsApp:
			'Балансыңызды толтыру үшін бізге WhatsApp-қа жазыңыз.',
		isNumberOfPersonInputEnabled: 'Адам санын енгізу қосылған',
		isCarpoolEnabled: 'Жолсерік қосылған',
		showOldOrders: 'Ескі тапсырыстарды көрсету',
		takeOrderImmediately: 'Тез арада тапсырыс қабылдау',
		spendBalanceOn: 'Балансты жұмсау',
		onComplete: 'Орындаған кезде',
		onAccept: 'Қабылдаған кезде',
		noAccess: 'Рұқсат жоқ',
		empty: 'Бос',
		telegramGroupChatId: 'Телеграм топтың ID-ы',
		telegramBotToken: 'Телеграм боттың токені',
		telegramGroup: 'Телеграм тобы',
		timezone: 'Уақыт белдеуі',
		driversOnTheMapTimeout: 'Картадағы жүргізуші уақыты (секунд)',
		inFreeWaitFor: 'Тегін тарифта қанша секунд күту керек',
		isFreeTariffEnabled: 'Тегін тариф қосылған',
		clone: 'Клондау',
		name: 'Аты',
		location: 'Орналасқан жері',
	},
}

export default messages
