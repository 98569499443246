import Axios from 'axios'
import axiosRetry from 'axios-retry'
import server from '../server.json'
import store from './store'

export var options = {
	baseURL: 'https://tezdet.com',
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
	},
}

if (process.env.NODE_ENV === 'development') {
	options.baseURL = server.baseURL
}

store.state.server.baseURL = options.baseURL

window.axios = Axios.create(options)

window.axios.defaults.transformResponse =
	window.axios.defaults.transformResponse.concat((data) => {
		return data
	})

axiosRetry(window.axios, {
	retries: Infinity,
	shouldResetTimeout: true,

	retryDelay: (retryCount) => {
		return retryCount * 2000
	},

	retryCondition: (error) => {
		if (error.response === undefined || error.message === 'Network Error') {
			// Could not connect the server OR no internet connection.

			return true
		} else if (error.response.status >= 500 && error.response.status <= 599) {
			// Server error.

			return true
		}

		return false
	},
})

export default window.axios
